import { graphql } from "gatsby";
import { ReactElement } from "react";

import Diseases from "../../../organisms/health-questionnaire/Diseases";
import OnboardingLayout from "../../../organisms/lead/OnboardingLayout";

interface PageProps {
  data: {
    allContentfulDiseasesList: {
      nodes: { node_locale: string; type: string; list: { title: string; diseaseId: string }[] }[];
    };
  };
}

const DisesasesPage = ({ data }: PageProps): ReactElement => (
  <OnboardingLayout>
    <Diseases data={data.allContentfulDiseasesList.nodes} />
  </OnboardingLayout>
);

export default DisesasesPage;

export const query = graphql`
  query DiseasesPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulDiseasesList {
      nodes {
        ...DiseasesFragment
      }
    }
  }
`;
