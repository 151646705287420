import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import Button from "../../atoms/button/Button";
import Checkbox from "../../atoms/checkbox/Checkbox";
import Heading from "../../atoms/heading/Heading";
import {
  useDiseasesList,
  useLead,
  useSetLead,
} from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import { Events, track } from "../../utils/analytics";
import { rollbar } from "../../utils/rollbar";
import PageStepForm from "../page-step-form/PageStepForm";
import * as styles from "./Diseases.module.scss";

interface DiseasesProps {
  data: { node_locale: string; type: string; list: { title: string; diseaseId: string }[] }[];
}

interface DiseasesFormValues {
  petDiseases: string[];
}

const Diseases = ({ data }: DiseasesProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const lead = useLead();
  const setLead = useSetLead();
  const nextUrl: string = `/${i18n.language}/onboarding/email/`;
  const diseasesList = useDiseasesList(data, i18n.language, lead.petSpecies);

  const handleCheckboxChange = (
    diseaseId: string,
    props: FormikProps<DiseasesFormValues>,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked } = event.target;
    const newDiseases = checked
      ? [...(props.values.petDiseases || []), diseaseId]
      : props.values.petDiseases?.filter((disease) => disease !== diseaseId) || [];
    props.setFieldValue("petDiseases", newDiseases);
  };

  const diseasesData = {
    initialValues: {
      petDiseases: lead.petDiseases || [],
    },
    handleSubmit: (
      values: DiseasesFormValues,
      { setSubmitting }: FormikHelpers<DiseasesFormValues>
    ): void => {
      if (!setLead) {
        return;
      }

      setSubmitting(true);

      setLead(values)
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          track(Events.FORM_ANSWERED, {
            healthQuestionnaire: {
              question: "Diseases",
              answer: updatedLead.petDiseases,
            },
          });

          setTimeout(() => navigate(nextUrl), 500);
        })
        .catch((): void => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<DiseasesFormValues>) => {
      const { isSubmitting, isValid, values } = props;

      return (
        <Form className={cn(styles.diseasesForm)}>
          <ul>
            {diseasesList.map(({ title, diseaseId }) => (
              <Checkbox
                key={diseaseId}
                id={diseaseId}
                name={diseaseId}
                onChange={(event) => handleCheckboxChange(diseaseId, props, event)}
                isChecked={values.petDiseases?.includes(diseaseId) || false}
                adoptionClassName={styles.diseaseCheckbox}
              >
                {title}
              </Checkbox>
            ))}
          </ul>

          <section className={styles.diseasesDisclaimer}>
            <Heading level={2}>{t("health_questionnaire.diseases.disclaimer.title")}</Heading>
            <ul>
              {[1, 2, 3].map((item) => (
                <li
                  key={item}
                  className={cn({
                    [styles.negative]: item === 3,
                  })}
                >
                  {t(`health_questionnaire.diseases.disclaimer.element.${item}.text`, {
                    petName: lead.petName,
                  })}
                </li>
              ))}
            </ul>
          </section>
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm title={t("health_questionnaire.diseases.question")} formData={diseasesData} />
  );
};

export const query = graphql`
  fragment DiseasesFragment on ContentfulDiseasesList {
    node_locale
    type
    list {
      title
      diseaseId
    }
  }
`;

export default Diseases;
